import { TicketshopPage } from "components/TicketshopPage";
import React from "react";

export default function RaceDayPage() {
  return (
    <TicketshopPage
      scriptString={`<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?salesChannel=whitelabel-website" data-fixr-shop-id="c9b328b7-d5d4-49ca-bf12-ed2de551af5f" data-fixr-theme="light" data-name-filter="student race day"></script>`}
      imageSrc="/event-logo-race-day.png"
      imageStyle={{ maxWidth: 600 }}
      title="STUDENT RACE DAY"
    />
  );
}
